import FooterComponent from "../Components/Navbar/Footer/footer";
import Products_Image from "../Images/Product_Bg_Image.jpg";
import UnderConstructionImage from "../Images/under-construction.jpg"

export default function Product() {
    return (
        <>
            <div className="relative">
                <img src={Products_Image} alt="About Us Image" className="h-96 w-full brightness-[0.3]" />
                <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
                    <div className="lg:text-6xl font-extrabold bgVideoText absolute">
                        <h1 className="heading text-white">Home | Products</h1>
                    </div>
                </div>
            </div>

            <img src={UnderConstructionImage} className="img-fluid w-full max-w-full h-full" alt="Under Construction Image " />
            
            <FooterComponent />
        </>
    )
}