import ContactUsForm from "../../Components/Navbar/ContactForm/contactForm";
import FooterComponent from "../../Components/Navbar/Footer/footer";
import Contact_Us_Image from "../../Images/AboutUs-Bg.jpg";
import './contact.css'


export default function ContactUs() {
    return (
        <>
            <div className="relative">
                <img src={Contact_Us_Image} alt="About Us Image" className="h-96 w-full brightness-[0.3]" />
                <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
                    <div className="lg:text-6xl font-extrabold bgVideoText absolute">
                        <h1 className="heading text-white">Home | Contact Us</h1>
                    </div>
                </div>
            </div>

            <ContactUsForm />

            <FooterComponent />
        </>
    )
}