import FooterComponent from "../Components/Navbar/Footer/footer";
import SideBySide_2_Component from "../Components/Navbar/Side-by-Side-2/SideBySide_2";
import About_Us_Image from "../Images/AboutUs-Bg.jpg";
import OurMissionImage from "../Images/Our_Mission_Image.webp"
import OurVisionImage from "../Images/Our_Vision_Image.webp"
import OurFactoryImage from "../Images/Factory-Image-8.webp"
import WhoWeAre1 from "../Images/Who_We_Are_Image_1.jpg"
import WhoWeAre2 from "../Images/Who_We_Are_Image_2.jpg"
import { BrowserRouter as Router, Route, Switch, Link, Outlet } from 'react-router-dom';

export default function AboutUs() {

    const AboutPageDetails = {
        Who_We_Are_1: {
            heading: "WHO WE ARE",
            subHeading: "COMPANY PROFILE",
            paraText: "shandong changlu tools co., ltd. is an industrial and trade enterprise which integrates hardware tools, automobile maintenance tools, machine repair tools and household tools development, production and sales. it is located in linyi, shandong province, the capital of logistics in china. the company covers an area of 50,000 square meters, with a production and storage area of 25,000 square meters. it mainly deals in 16 categories and more than 5,000 specifications such as changlu brand sets of tools, sleeves, sleeve accessories, wrenches, screwdrivers, fastening, shearing, clamps, knocking, etc. it is widely used in machinery, petroleum, chemical, electric power, automobile manufacturing and maintenance, etc. industry. the company is currently the director of china wujiaochua business association, the executive director of the national federation of industry and commerce hardware and electrical chamber of commerce and the director unit of shandong hardware and electrical chamber of commerce.",
            imgUrl: WhoWeAre1,
            Img_At_First: true,
            Img_At_End: false,
        },
        Who_We_Are_2: {
            heading: "WHO WE ARE",
            subHeading: "COMPANY PROFILE",
            paraText: "Through the certification of iso 9001 international quality management system, the company has won the honorary titles of ten national enterprises in china's automobile  maintenance industry, honest manufacturer of hardware chamber of commerce and industry, brand construction demonstration enterprise of hardware and electromechanical industry in shandong province, specialty and innovation enterprise of shandong province, contract-abiding and credit-abiding enterprise of shandong province, etc. cctv honorary broadcasting brand was awarded the title of top ten products of maintenance tools by shanghai automobile insurance chamber of commerce and was awarded the honorary title of honest manufacturer by the national federation of industry and commerce hardware machinery chamber of commerce. long deer insulation tools have passed german vde certification.",
            imgUrl: WhoWeAre2,
            Img_At_First: false,
            Img_At_End: true,
        },
        Our_Mission: {
            heading: "OUR MISSION",
            subHeading: "COMPANY PROFILE",
            paraText: "At Changlu Tools, our mission is to be the trusted partner for creators of all levels, from seasoned professionals to passionate DIYers. We achieve this by providing a comprehensive range of industry-leading power tools and reliable hand tools. We believe that the right tools are essential for transforming ideas into reality, and we are dedicated to offering solutions that empower users to tackle any project with confidence. Our commitment to innovation fuels the development of cutting-edge power tools that boast exceptional performance and durability. We invest heavily in research and development to ensure our power tools integrate the latest advancements in technology, delivering unmatched efficiency and functionality for even the most demanding tasks.",
            imgUrl: OurMissionImage,
            Img_At_First: true,
            Img_At_End: false,
        },
        Our_Vision: {
            heading: "OUR VISION",
            subHeading: "COMPANY PROFILE",
            paraText: "At Changlu Tools, we dream of a boundless future where creativity flourishes without constraints. In our vision, power tools and hand tools seamlessly merge with the user's will, effortlessly translating ideas into tangible realities with unparalleled precision and efficiency. We firmly believe that when tools are both intuitive and accessible, they have the power to democratize the act of creation, making it accessible to all. Our mission is to create a world where seasoned professionals and aspiring enthusiasts alike have access to the tools they need to bring their boldest visions to life. Through relentless innovation and unwavering dedication, Changlu Tools is committed to expanding the frontiers of possibility and unlocking the full potential of human ingenuity. Join us as we embark on a journey to transform dreams into reality, one tool at a time.",
            imgUrl: OurVisionImage,
            Img_At_First: false,
            Img_At_End: true,
        },
        Our_Factory: {
            heading: "OUR FACTORY",
            subHeading: "COMPANY PROFILE",
            paraText: "We operate a modern 6,000-square-meter production facility strategically situated near major transportation routes for efficient logistics. Our factory focuses on research, development, and innovation, producing a wide range of auto protection tools, car tools, tool sets, and more.Our dedication to quality and customer satisfaction has garnered them the trust of partners worldwide.",
            imgUrl: OurFactoryImage,
            Img_At_First: true,
            Img_At_End: false,
        },

    }

    const Pdf_File_Url = "http://localhost:3000/ChangluCatalog.pdf"

    const downloadFileAtUrl = (url) => {
        const fileName = url.split('/').pop()
        const aTag = document.createElement('a')
        aTag.href = url
        aTag.setAttribute('download', fileName)
        document.body.appendChild(aTag)
        aTag.click()
        aTag.remove()
    }

    return (
        <>
            <div className="relative">
                <img src={About_Us_Image} alt="About Us Image" className="h-96 w-full brightness-[0.3]" />
                <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
                    <div className="lg:text-6xl font-extrabold bgVideoText absolute">
                        <h1 className="heading text-white">Home | About Us</h1>
                    </div>
                </div>
            </div>


            <SideBySide_2_Component {...AboutPageDetails.Who_We_Are_1} />
            <SideBySide_2_Component {...AboutPageDetails.Who_We_Are_2} />
            <SideBySide_2_Component {...AboutPageDetails.Our_Mission} />
            <SideBySide_2_Component {...AboutPageDetails.Our_Vision} />
            <SideBySide_2_Component {...AboutPageDetails.Our_Factory} />


            <h1 className="text-3xl font-bold mt-4 text-center">Discover more! Download our catalog today!</h1>
            <div className="flex justify-center">
                <button className="btn btn-success p-2.5 text-xl my-4" onClick={() => (downloadFileAtUrl(Pdf_File_Url))}>Download Catalog</button>
            </div>


            <FooterComponent />
        </>
    )
}