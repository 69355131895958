import Button from "../Components/Navbar/Button/button";
import CardSliderCarousel from "../Components/Navbar/CardSlider/cards_slider";
import FooterComponent from "../Components/Navbar/Footer/footer";
import product1 from "../Images/Product_1.jpg"
import product2 from "../Images/Product_2.jpg"
import product3 from "../Images/Product_3.jpg"
import product4 from "../Images/Product_4.jpg"
import product5 from "../Images/Product_5.jpg"
import product6 from "../Images/Product_6.jpg"
import product7 from "../Images/Product_7.jpg"
import product8 from "../Images/Product_8.jpg"
// import AboutVideo from "../Images/AboutUsVideo1.gif"
import AboutVideo from "../Images/AboutUsVideo.mp4"
import Chunglu_Map from "../Images/Chunglu-Map.png"
import About_Banner from "../Images/About_Banner.png"
import About_Chunglu_Img from "../Images/About_Chunglu_Img.jpg"
import AboutCountUp from "../Components/Navbar/Countup/countup";
import SideBySideComponent from "../Components/Navbar/Side-by-Side/SideBySide";
import { IoIosPlayCircle } from "react-icons/io";
import CarouselPage from "../Components/Navbar/Carousel/carouselPage";
import AboutUsBgVideo from "../Components/Navbar/BgVideo/AboutUsVideo";

export default function Home() {

    const hotProducts = [
        {
            imageUrl: product1,
            content: "Best Adjustable Wrench parameter feature Products Description Best Adjustable Wrench The top of the wrench is polished and the surface is plated with black nickel, making it wear-resistant and...",
            title: "Best Adjustable Wrench"
        },
        {
            imageUrl: product2,
            content: "Air Gun For Blowing Dust parameter feature Products Description Air Gun For Blowing Dust The air duster gun is a versatile and convenient tool that is widely used for cleaning and dust removal...",
            title: "Air Gun For Blowing Dust"
        },
        {
            imageUrl: product3,
            content: "Electric Polishing Machine parameter feature Products Description Electric Polishing Machine Angle polishers are a versatile and essential tool for any DIY enthusiast or professional. They can be...",
            title: "Electric Polishing Machine"
        },
        {
            imageUrl: product4,
            content: "Hot Glue Gun parameter feature Products Description Hot Glue Gun The hot glue gun is an essential tool in crafting and DIY projects. It is a versatile device that generates a potent adhesive to...",
            title: "Hot Glue Gun"
        },
        {
            imageUrl: product5,
            content: "Rechargeable Lithium Wrench parameter details Products Description Rechargeable Lithium Wrench Introducing the Lithium-ion Rechargeable Impact Wrench with a maximum output of 900N torque. This...",
            title: "Rechargeable Lithium Wrench"
        },
        {
            imageUrl: product6,
            content: "Paint Spray Gun parameter ： feature ： Products Description： Paint Spray Gun The paint spray gun is a high-quality device that is designed for efficient spray painting. With a nozzle diameter of...",
            title: "Paint Spray Gun"
        },
        {
            imageUrl: product7,
            content: "Precision Screwdrivers For Watch Repair parameter feature Products Description Precision Screwdrivers For Watch Repair Made with high-quality chromium-vanadium steel material, the entire set is...",
            title: "Precision Screwdrivers"
        },
        {
            imageUrl: product8,
            content: "Straight Line Scissors parameter details Products Description Straight Line Scissors Its blade is made of chrome vanadium steel, which makes it sharp and long-lasting. The cutter head is secured...",
            title: "Straight Line Scissors"
        },
    ]

    const HomePage_Details = {
        About_Changlu1: {
            headingPara: `Tools Pakistan is committed to scientific management, high-quality standards, honesty, and ongoing innovation.`,
            paraText: "Our focus is on efficient operations, delivering superior tools, maintaining integrity, and advancing industry standards. We are dedicated to excellence, ensuring our customers receive top-tier tools and building lasting partnerships.",
            secondHeadingPara: "Videos will Let You Know More About Us!",
            label: "More Videos",
            buttonLink: "https://www.youtube.com/@Changlutools",
        },
        About_Changlu2: {
            heading: `ABOUT CHANGLU`,
            subHeading: "COMPANY PROFILE",
            paraText: "Explore Excellence with Changlu Tools – a distinguished industrial enterprise specializing in developing, producing, and selling top-quality tools. With extensive facilities covering 30,000 square meters and a range of 16 categories and over 5,000 specifications, our versatile tools are trusted across machinery, petroleum, chemical, electric power, and automobile manufacturing industries. Recognized for quality and innovation, Changlu Tools is honored as a leader in China's automobile maintenance sector.",
            secondHeading: "CHANGLU Offers:",
            imgUrl: About_Chunglu_Img,
            label: "More Details",
            buttonLink: "/",
            buttonHave: true,
            offers: true
        },
    }

    return (
        <>
            <section className="hero-section">
                <div>
                    <CarouselPage />
                </div>
            </section>

            <section className="About-us-Content my-5 flex flex-col justify-center container">
                <div className="mb-4">
                    <SideBySideComponent {...HomePage_Details.About_Changlu2} />
                </div>
                <div className="flex items-center justify-center">
                    <AboutUsBgVideo videoLink={AboutVideo} />
                </div>
                <div className="flex items-center">
                    <div>
                        <AboutCountUp />
                    </div>
                </div>
                <div className="flex items-center justify-center" style={{ position: 'relative', zIndex: '2', maxWidth: '100%', overflow: 'hidden' }}>
                    <a href="https://youtu.be/efOxbYgN0U0?si=eD5JAb4htyQLBUyv" target="_blank" rel="noopener noreferrer" className="relative">
                        <img src={About_Banner} alt="About Us Picture" style={{ maxWidth: '100%' }} className="transform transition-transform duration-500 hover:scale-105" />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white lg:text-9xl md:text-6xl">
                            <IoIosPlayCircle />
                        </div>
                    </a>
                </div>
                <div className="text-center container mb-4" style={{ position: 'relative', zIndex: '2' }}>
                    <div className="heading-para-large">
                        <div className="heading-para-inner">
                            <h2 className="text-2xl font-medium italic ">
                                {/* <span className="text-[#006429] text-6xl">&ldquo;</span> */}
                                <span className="text-[#006429] font-bold">"Changlu </span>
                                {HomePage_Details.About_Changlu1.headingPara}"
                                <p className="text-lg font-semibold leading-[30px]">
                                    {HomePage_Details.About_Changlu1.paraText}
                                </p>
                            </h2>
                        </div>
                    </div>

                    <div className="d-flex flex-md-row flex-column p-3">
                        <h2 className="text-3xl font-bold italic lg:leading-[50px] mr-[15px]">
                            {HomePage_Details.About_Changlu1.secondHeadingPara}
                        </h2>
                        <div className="mt-1">
                            <Button label={HomePage_Details.About_Changlu1.label} buttonLink={HomePage_Details.About_Changlu1.buttonLink} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="Network-Section">
                <h1 className="text-center text-3xl italic font-bold my-5 tracking-widest">OUR <span className="text-[#006600]">GLOBAL</span> NETWORK</h1>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <img src={Chunglu_Map} alt="Chunglu Map" className="max-w-screen flex justify-center items-center h-auto" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="Product-Cards">
                <div className="text-center container">
                    <h1 className="text-center text-4xl pt-4 font-bold tracking-wide"><span className="text-[#006600]">CHANGLU</span> HAS QUALITY PRODUCTS</h1>
                    <p className="text-lg font-semibold leading-[25px] my-3">
                        We're mainly deals in 16 categories and more than 5,000 specifications such as changlu brand <br /> sets of tools, socket, socket accessories, wrenches, screwdrivers, fastening, shearing, clamps, knocking, renovation, <br /> welding, electronics, measurement, automobile repair tools, etc.
                    </p>
                    <Button label="More Products" buttonLink="/product" />
                </div>
                <div className="container py-5">
                    <CardSliderCarousel products={hotProducts} />
                </div>
            </section>

            <section>
                <FooterComponent />
            </section>

        </>
    );
}



{/* <section className="container">
                <div>
                    <h1 className="text-center text-4xl pt-4 font-bold">Hot Products</h1>
                </div>
                <div className="p-5">
                    <div className="row m-0 p-0">
                        {hotProducts.map((product, index) => (
                            <div className="col-md-3" key={index}>
                                <div>
                                    <div className="py-2">
                                        <Card
                                            imageUrl={product.imageUrl}
                                            content={product.content}
                                            title={product.title}
                                        />
                                    </div>
                                </div>
                                <div className="py-2 d-flex justify-content-center">
                                    <Button
                                        label="View More"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}





{/* <section>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center gap-5">
                        <li className="page-item">
                            <button className="page-link">
                                Previous
                            </button>
                        </li>
                        <div className="row">
                            <div className="col-lg-4">
                                <li className="page-item disabled">
                                    <ProductCard ImgUrl={product1} title={HomePage_Details.About_Changlu1.label} />
                                </li>
                                <li className="page-item disabled">
                                    <ProductCard ImgUrl={product1} title={HomePage_Details.About_Changlu1.label} />
                                </li>
                                <li className="page-item disabled">
                                    <ProductCard ImgUrl={product1} title={HomePage_Details.About_Changlu1.label} />

                                </li>
                            </div>
                        </div>


                        <li className="page-item">
                            <button className="page-link">
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>

 </section> */}